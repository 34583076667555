import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { SelectInputProps } from "./types";
import { FaAngleDown } from "react-icons/fa";
const getFontSize = ({ fontSize, small }: SelectInputProps) => {
  return small ? "14px" : fontSize || "16px";
};
export const OptionWrapper = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.primary}; // Change color on hover
    color: ${({ theme }) => theme.colors.textLight};
  }
`;
export const OptionContentWrapper = styled.div<SelectInputProps>`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 100px;
  overflow-y: auto;
  z-index: 1;
  background: ${({ theme }) => theme.colors.backgroundLight};
`;
export const MultiSelectDropdownWrapper = styled.div<SelectInputProps>`
  color: ${({ theme }) => theme.colors.textLight} !important;
  margin-top: 5px;
  position: relative;
  width: 200px;
  & > div:last-child {
    cursor: "pointer";
  }
  color: ${({ theme }) => theme.colors.textLight} !important;
  font-size: ${getFontSize};
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  line-height: 2.5;
  border-radius: 10px;
  padding: 10px;
  background: ${({ theme }) => theme.colors.backgroundLight};
  outline: none;
  border: none;
  cursor: pointer;
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
  &.custom_input {
    color: ${({ theme }) => theme.colors.textDark};
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
export const DropdownIcon = styled(FaAngleDown)`
  margin-left: 5px;
`;

const Option = ({ children, value, onSelect, selected }: any) => {
  return (
    <OptionWrapper onClick={() => onSelect(value)}>{children}</OptionWrapper>
  );
};

export const MultiSelectDropdown = ({
  options,
  placeholder,
  setSelectedOptions,
  selectedOptions,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const handleSelect = (value: number, name: string) => {
    if (selectedOptions.includes(value)) {
      setSelectedOptions(
        selectedOptions.filter((option: any) => option !== value)
      );
    } else {
      setSelectedOptions([...selectedOptions, { label: name, value: value }]);
    }
  };

  const handleTriggerClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const availableOptions = options.filter(
    (option: { id: number }) =>
      !selectedOptions.some(
        (selected: { value: number }) => selected.value === option.id
      )
  );
  return (
    <MultiSelectDropdownWrapper
      ref={dropdownRef}
      className="custom_input"
      onClick={handleTriggerClick}
      style={{ cursor: "pointer" }}
    >
      <div style={{ color: selectedOptions.length > 0 ? "white" : "gray" }}>
        {selectedOptions.length > 0
          ? selectedOptions
              .map((option: { label: string }) => option.label)
              .join(", ")
          : placeholder}
      </div>
      <DropdownIcon />
      {isOpen && (
        <OptionContentWrapper>
          {availableOptions.map(
            (chain: { id: number; attributes: { name: string } }) => (
              <Option
                key={chain.id}
                value={chain.id}
                onSelect={(value: number, name: string) =>
                  handleSelect(value, chain.attributes.name)
                }
                selected={selectedOptions.includes(chain.attributes.name)}
              >
                {chain.attributes.name}
              </Option>
            )
          )}
        </OptionContentWrapper>
      )}
    </MultiSelectDropdownWrapper>
  );
};
