import styled, { DefaultTheme } from "styled-components";
import { space, typography } from "styled-system";
import { TextInputProps } from "./types";

const getFontSize = ({ fontSize, small }: TextInputProps) => {
  return small ? "14px" : fontSize || "16px";
};

const TextInput = styled.input<TextInputProps>`
  color: ${({ theme }) => theme.colors.textLight} !important;

  font-size: ${getFontSize};
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  line-height: 2.5;
  border-radius: 10px;
  padding: 10px;
  background: ${({ theme }) => theme.colors.backgroundLight};
  outline: none;
  border: none;

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  }
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
  ${space} ${typography};
  &.custom_input {
    color: ${({ theme }) => theme.colors.textDark};
    width: 100%;
  }
`;

TextInput.defaultProps = {
  color: (theme: DefaultTheme) => theme.theme.colors.text,
  small: false,
};

export default TextInput;
