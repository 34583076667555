import { styled } from "styled-components";
import { BoxProps } from "./types";
import { background, border, layout, position, space } from "styled-system";

const Box = styled.div<BoxProps>`
  ${background}
  ${border}
  ${layout}
  ${position}
  ${space}
   &.custom_box {
    flex: 1;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  &.FormCss {
    margin-top: 20px;
    box-shadow: ${({ theme }) => theme.colors.boxShadowPrimary};
    border-radius: 20px;
    padding: 40px;
    width: 60%;
    border: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  }
`;
export default Box;
