import { useEffect, useState } from "react";
import { Flex, Box } from "../../components/Box";
import Text from "../../components/Text/Text";
import useTheme from "../../hooks/useTheme";
import Button from "../../components/Button/Button";
import { PlusIcon } from "../../config/images";
import { useNavigate } from "react-router-dom";
import { StreamItem } from "../../components/ListItem";
import { getStreams } from "../../api/api";
import { StreamListProps } from "../../components/ListItem/types";
import { PaginationControl } from "react-bootstrap-pagination-control";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import SideBarMenu from "../../components/SideBar/SideBar";
const Streams = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [streamList, setStreamList] = useState<StreamListProps[]>([]);
  const [streamPagination, setStreamPagination] = useState({
    page: 1,
    pageSize: 3,
    total: 0,
  });
  useEffect(() => {
    const getAllStreams = async () => {
      const streamListing = await getStreams({
        page: streamPagination.page,
        pageSize: 3,
      });
      setStreamList(streamListing?.data?.data);
      setStreamPagination(streamListing?.data?.meta?.pagination);
    };

    getAllStreams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlePageChange = async (page: number) => {
    const streamListing = await getStreams({
      page,
      pageSize: 3,
    });

    setStreamList(streamListing?.data?.data);
    setStreamPagination({
      ...streamPagination,
      page: page,
      total: streamListing?.data?.meta?.pagination.total,
    });
  };

  return (
    <>
      <Flex>
        <Box
          minWidth={"250px"}
          width={"auto"}
          marginRight={"20px"}
          // position={"sticky"}
        >
          <SideBarMenu />
        </Box>
        <Flex
          background={theme?.colors.background}
          flexDirection={"column"}
          justifyContent={"center"}
          margin={"1.5rem 1rem"}
        >
          <Flex flexDirection={"column"}>
            <Text fontSize="30px" fontWeight="800">
              Streams
            </Text>
            <Flex>
              <Button
                startIcon={
                  <PlusIcon size={20} style={{ marginRight: "10px" }} />
                }
                style={{
                  marginTop: "20px",
                }}
                type="submit"
                onClick={() => navigate("/addstream")}
              >
                Create a new stream
              </Button>
            </Flex>
            <Text
              marginTop={"40px"}
              marginBottom={"1rem"}
              fontSize="30px"
              fontWeight="800"
            >
              Your Streams
            </Text>

            {streamList.map((item) => {
              return (
                <StreamItem
                  item={item.attributes}
                  streamId={item.id}
                  setStreamList={setStreamList}
                />
              );
            })}
          </Flex>
          {streamList.length > 0 && (
            <PaginationWrapper flexDirection={"row"} justifyContent={"center"}>
              <PaginationControl
                page={streamPagination.page}
                between={3}
                limit={3}
                changePage={(page) => {
                  handlePageChange(page);
                }}
                ellipsis={1}
                total={streamPagination.total}
              />
            </PaginationWrapper>
          )}
        </Flex>
      </Flex>
    </>
  );
};
export default Streams;
const PaginationWrapper = styled(Flex)`
  .pagination {
    margin-top: 20px;
    .page-item {
      margin-right: 10px;
      &.active {
        .page-link {
          background: ${({ theme }) => theme.colors.primary};
          color: white;
          font-weight: bold;
          font-size: 16px;
          border: 1px solid ${({ theme }) => theme.colors.primary};
          border-radius: 8px;
        }
      }
      .page-link {
        color: ${({ theme }) => theme.colors.primary};
        border-radius: 8px;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
`;
