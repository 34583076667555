import { createContext, useState } from "react";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import { light, dark } from "../theme";
const CACHE_KEY = "IS_DARK";

const ThemeContext = createContext({
  isDark: null,
  toggleTheme: () => {},
});

const ThemeContextProvider = ({ children }: any) => {
  const [isDark, setIsDark] = useState(
    localStorage.getItem(CACHE_KEY) ? JSON.parse(CACHE_KEY) : false
  );

  const toggle = () => {
    localStorage.setItem(CACHE_KEY, JSON.stringify(!isDark));
    setIsDark(!isDark);
  };

  return (
    <ThemeContext.Provider
      value={{
        isDark: isDark,
        toggleTheme: toggle,
      }}
    >
      <SCThemeProvider theme={dark ? dark : light}>{children}</SCThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeContextProvider };
