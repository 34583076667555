import React from "react";

import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoutes = () => {
    const localStorageToken = localStorage.getItem("jwt");

    return localStorageToken ? <Outlet /> : <Navigate to="/signin" replace />;
};

export default ProtectedRoutes;