import { useForm } from "react-hook-form";
import { Flex, Box } from "../../components/Box";
import Button from "../../components/Button/Button";
import Label from "../../components/Label/Label";
import Text from "../../components/Text/Text";
import TextInput from "../../components/TextInput/TextInput";
import useTheme from "../../hooks/useTheme";
import { ForgotPasswordProps } from "../../interface";
import { useState } from "react";
import { forgotPassword } from "../../api/api";
import { AxiosError } from "axios";

const ForgotPassword = () => {
  const { theme } = useTheme();
  const [submitError, setSubmitError] = useState("");
  const [apiResponseStatus, setApiResponseStatue] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    register,
    getValues,
  } = useForm<ForgotPasswordProps>();
  const onSubmit = async (data: ForgotPasswordProps) => {
    try {
      const apiRespone = await forgotPassword(data);
      setApiResponseStatue(apiRespone.data.ok);
    } catch (error) {
      if (error instanceof AxiosError) {
        setSubmitError(error?.response?.data?.error.message);
      }
    }
  };
  return (
    <Flex
      flexDirection={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100vh"}
      background={theme?.colors.background}
    >
      <Flex
        borderRadius={"20px"}
        flexDirection={"column"}
        maxWidth={"550px"}
        style={{
          boxShadow: theme?.colors.boxShadowPrimary,
          border: `1px solid ${theme?.colors.borderPrimary}`,
        }}
      >
        {apiResponseStatus ? (
          <Flex
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            padding={"30px 0px "}
          >
            <Text fontSize="30px" fontWeight={"bold"}>
              Request Sent Successfully
            </Text>
            <Text
              fontSize="16px"
              fontWeight={"500"}
              padding={"2rem 2rem"}
              textAlign={"center"}
            >
              We have sent a confirmation email to{" "}
              <span style={{ color: theme?.colors.primary }}>
                {getValues("email")}
              </span>{" "}
              Please check your email..
            </Text>
          </Flex>
        ) : (
          <Box>
            <Flex
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              padding={"30px 0px "}
            >
              <Text fontSize="30px" fontWeight={"bold"}>
                Forgot Password
              </Text>
              <Text fontSize="16px" fontWeight={"500"} padding={"0 2rem"}>
                We will send you An email with verification link to reset your
                password, Please enter your email address and click on continue.
              </Text>
            </Flex>

            <form
              style={{
                flexDirection: "column",
                marginBottom: "40px",
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              {!!submitError && (
                <Box style={{ textAlign: "center", marginTop: "10px" }}>
                  <Text color={theme?.colors.danger}>{submitError}</Text>
                </Box>
              )}

              <Label
                margin={"10px 60px"}
                fontSize="16px"
                fontWeight="600"
                flexDirection="column"
                style={{ color: theme?.colors.text }}
              >
                Email address
                <TextInput
                  type="email"
                  placeholder="Enter your email address"
                  fontSize="16px"
                  fontWeight="550"
                  style={{ color: theme?.colors.textDark }}
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
                {errors.email && (
                  <Text color={theme?.colors.danger}>
                    {errors.email.message}
                  </Text>
                )}
              </Label>

              <Flex justifyContent={"center"}>
                <Button
                  style={{
                    width: "40%",
                    marginTop: "30px",
                  }}
                  type="submit"
                  size={60}
                >
                  Submit
                </Button>
              </Flex>
            </form>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default ForgotPassword;
