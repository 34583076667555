import styled from "styled-components";
import { flexbox, space, typography } from "styled-system";
import { LabelProps } from "./types";

const getFontSize = ({ fontSize, small }: LabelProps) => {
  return small ? "14px" : fontSize || "16px";
};

const Label = styled.label<LabelProps>`
  color: ${({ theme }) => theme.text};
  font-size: ${getFontSize};
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  line-height: 1.5;
  border: none;
  ${flexbox};
  display: flex;
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
  ${space}
  ${typography}
`;

Label.defaultProps = {
  color: "text",
  small: false,
};

export default Label;
