import { NotificationTypeEnum } from "./enum";

export const routes = {
  Stream: "/streams",
  UpdateStream: "/updatestream",
  ForgotPassword: "/forgot-password",
  Login: "/signin",
};

export const NotificationList = [
  {
    label: "Webhook URL",
    value: NotificationTypeEnum.WebHook_URL,
    disabled: false,
  },
  {
    label: "Email",
    value: NotificationTypeEnum.Email,
    disabled: false,
  },
  {
    label: "Slack (coming soon)",
    value: "",
    disabled: true,
  },
  {
    label: "Discord (coming soon)",
    value: "",
    disabled: true,
  },
];
