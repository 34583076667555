import { Box, Flex } from "../../components/Box";
import Text from "../../components/Text/Text";
import useTheme from "../../hooks/useTheme";
import Button from "../../components/Button/Button";
import { BackIcon } from "../../config/images";
import Label from "../../components/Label/Label";
import TextInput from "../../components/TextInput/TextInput";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { UpdateStreamProp } from "../../interface";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import {
  getStreamById,
  removeStreamAddress,
  updateStream,
} from "../../api/api";
import AddAddress from "../AddAddress/AddAddress";
import DialogAlert from "../../components/AlertDialog/AlertDialog";
import { routes } from "../../utils/constant";
import { StreamListProps } from "../../components/ListItem/types";
import { ValidationErrors, webhookUrlValidation } from "../../utils/enum";
const UpdateStream = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const { state } = useLocation();
  const streamId = state?.streamId;
  const [submitError, setSubmitError] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [streams, setStreams] = useState<StreamListProps | null>(null);
  const [dialogAlert, setDialogAlert] = useState(false);
  useEffect(() => {
    const getStreamsById = async () => {
      const streamObject = await getStreamById(streamId);
      setStreams(streamObject.data.data);
    };

    getStreamsById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (streams?.attributes) {
      setValue("webhook_url", streams?.attributes?.webhook_url);
      setValue("title", streams?.attributes?.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streams]);

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    setValue,
  } = useForm<UpdateStreamProp>({
    defaultValues: {
      webhook_url: streams?.attributes.webhook_url,
      title: streams?.attributes?.title,
    },
  });

  console.log(streams?.attributes?.title);
  const onSubmit = async (data: UpdateStreamProp) => {
    if (streams?.attributes?.chains) {
      const chainArray = streams?.attributes?.chains?.data?.map(
        (item: { id: number }) => item.id
      );
      try {
        const updateStreamObject = {
          title: data.title,
          chains: chainArray,
          webhook_url: data.webhook_url,
        };
        await updateStream({ data: updateStreamObject }, streamId);
        setDialogAlert(true);
      } catch (error) {
        if (error instanceof AxiosError) {
          setSubmitError(error.response?.data.error.message);
        }
      }
    }
  };

  const handleReset = () => {
    reset({
      webhook_url: streams?.attributes.webhook_url,
    });
  };
  const handleRemoveAddress = async (id: number) => {
    await removeStreamAddress(id);
    const streamObject = await getStreamById(streamId);
    setStreams(streamObject.data.data);
  };
  const streamAddressLength = streams?.attributes?.addresses?.data?.length ?? 0;
  return (
    <>
      <Flex
        background={theme?.colors.background}
        flexDirection={"column"}
        justifyContent={"center"}
      >
        <Flex margin={"40px 110px"} flexDirection={"column"}>
          <Flex flexDirection={"row"} justifyContent={"space-between"}>
            <Button
              startIcon={<BackIcon size={20} style={{ marginRight: "8px" }} />}
              style={{
                marginTop: "20px",
              }}
              onClick={() => navigate("/streams")}
            >
              Go Back
            </Button>
          </Flex>
          <Text marginTop={"20px"} fontSize="30px" fontWeight="800">
            Create new stream
          </Text>
          <Text marginTop={"44px"} fontSize="30px" fontWeight="800">
            XRP Stream Configuration
          </Text>

          <Box width={"60% "}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {!!submitError && (
                <Box>
                  <Text color={theme?.colors.danger}>{submitError}</Text>
                </Box>
              )}
              <Box className="FormCss" width={"100% !important"}>
                <Flex flexDirection={"column"} style={{ rowGap: "1rem" }}>
                  {streamAddressLength > 0 ? (
                    streams?.attributes?.addresses?.data?.map(
                      (item, index: number) => (
                        <>
                          <Flex
                            alignItems={"center"}
                            key={index}
                            flexDirection={"row"}
                            style={{ columnGap: "2rem" }}
                          >
                            <TextInput
                              defaultValue={item?.attributes.address}
                              type="text"
                              placeholder="Enter xrp address"
                              fontSize="16px"
                              fontWeight="550"
                              width="100%"
                              readOnly
                              className="custom_input"
                            />
                            <Button
                              padding={0}
                              type="button"
                              onClick={() => handleRemoveAddress(item.id)}
                              style={{
                                backgroundColor: theme?.colors.success,
                                width: "10%",
                              }}
                            >
                              X
                            </Button>
                          </Flex>
                        </>
                      )
                    )
                  ) : (
                    <Text fontSize="16px" fontWeight={"600"}>
                      Add wallet address to the stream
                    </Text>
                  )}
                  <Flex margin={"20px"} justifyContent={"center"}>
                    <Button
                      style={{
                        width: "25%",
                        marginRight: "10px",
                      }}
                      padding={0}
                      type="button"
                      onClick={() => setOpenModal(true)}
                    >
                      Add new address
                    </Button>
                  </Flex>
                </Flex>
              </Box>
              <Box className="FormCss" width={"100% !important"}>
                <Flex flexDirection={"column"} flexWrap={"wrap"}>
                  <Flex alignItems={"center"}>
                    <Label
                      fontSize="16px"
                      fontWeight="600"
                      flexDirection="column"
                      style={{
                        color: theme?.colors.text,
                        margin: "10px 0",
                      }}
                    >
                      Stream Title
                    </Label>
                  </Flex>
                  <TextInput
                    {...register("title", { required: true })}
                    type="text"
                    placeholder="Enter title"
                    fontSize="16px"
                    fontWeight="550"
                    marginTop={"10px"}
                    style={{ color: theme?.colors.textDark }}
                  />
                  {errors.title && (
                    <Text color={theme?.colors.danger} marginTop={"10px"}>
                      {ValidationErrors.Title}
                    </Text>
                  )}
                  <Flex alignItems={"center"}>
                    <Label
                      fontSize="16px"
                      fontWeight="600"
                      flexDirection="column"
                      style={{
                        color: theme?.colors.text,
                        margin: "10px 0",
                      }}
                    >
                      Stream Webhook Url
                    </Label>
                  </Flex>
                  <TextInput
                    {...register("webhook_url", webhookUrlValidation)}
                    type="text"
                    placeholder="Enter webhook URL"
                    fontSize="16px"
                    fontWeight="550"
                    marginTop={"10px"}
                    style={{ color: theme?.colors.textDark }}
                  />
                  {errors.webhook_url && (
                    <Text color={theme?.colors.danger} marginTop={"10px"}>
                      {errors?.webhook_url?.message}
                    </Text>
                  )}
                </Flex>
              </Box>
              <Flex marginTop={"1rem"} justifyContent={"center"}>
                <Button
                  style={{
                    marginRight: "10px",
                  }}
                  type="button"
                  onClick={handleReset}
                >
                  Reset to default
                </Button>
                <Button
                  type="submit"
                  style={{
                    backgroundColor: theme?.colors.success,
                  }}
                >
                  Update stream
                </Button>
              </Flex>
            </form>
          </Box>
        </Flex>
      </Flex>
      <AddAddress
        openModal={openModal}
        streamId={streamId}
        setOpenModal={setOpenModal}
        setStreams={setStreams}
      />
      <DialogAlert
        active={dialogAlert}
        title={"Success!"}
        description={"Stream updated successfully"}
        onClose={(event) => {
          setDialogAlert(false);
          navigate(routes.Stream);
        }}
      />
    </>
  );
};
export default UpdateStream;
