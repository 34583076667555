import { firebaseImage } from "../../config/images";
import useTheme from "../../hooks/useTheme";
import { Flex } from "../Box";
import { Text } from "../Text";
import { CardToolProps } from "./types";

const CardTool = (props: CardToolProps) => {
  const { theme } = useTheme();

  return (
    <Flex
      justifyContent={"center"}
      flexDirection={"column"}
      borderRadius={"20px"}
      background={theme?.colors.backgroundAlt}
      margin={"10px"}
      style={{
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.1)",
      }}
      padding={"10px 30px"}
    >
      <img
        width={"70px"}
        height={"70px"}
        style={{ alignItems: "center" }}
        src={props.image}
      />
      <Text
        fontSize="20px"
        style={{ color: theme?.colors.textDark }}
        fontWeight={"600"}
      >
        {props.title}
      </Text>
      <a
        style={{
          textDecoration: "none",
          color: theme?.colors?.primary,
          fontWeight: "550",
          marginBottom: "16px",
          marginTop: "8px",
        }}
        href="../Signup"
      >
        Read Tutorial
      </a>
    </Flex>
  );
};
export default CardTool;
