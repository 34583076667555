import * as AlertDialog from "@radix-ui/react-alert-dialog";
import styled from "styled-components";
import { DialogAlertProps } from "../ListItem/types";
import Button from "../Button/Button";
import { Flex } from "../Box";

const DialogAlert = (props: DialogAlertProps) => {
  const { active, title, description, onClose } = props;
  return (
    <>
      {active && (
        <AlertDialog.Root open={active}>
          <StyledOverlay />
          <StyledContent>
            <AlertDialog.Title>{title}</AlertDialog.Title>
            <AlertDialog.Description>{description}</AlertDialog.Description>
            <Flex justifyContent={"center"}>
              <Button onClick={onClose}>Ok</Button>
            </Flex>
          </StyledContent>
        </AlertDialog.Root>
      )}
    </>
  );
};
export default DialogAlert;

const StyledOverlay = styled(AlertDialog.Overlay)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StyledContent = styled(AlertDialog.Content)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 360px;
  border-radius: 0.75rem;
  box-shadow: ${({ theme }) => theme.colors.boxShadowPrimary};
  border: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  backdrop-filter: blur(100px);
  color: ${(theme) => theme.theme.colors.text};
  padding: 20px;
`;
