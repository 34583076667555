import { useState } from "react";
import { Flex, Box } from "../../components/Box";
import Button from "../../components/Button/Button";
import Label from "../../components/Label/Label";
import Text from "../../components/Text/Text";
import TextInput from "../../components/TextInput/TextInput";
import useTheme from "../../hooks/useTheme";
import { useNavigate } from "react-router-dom";
import { signUser } from "../../api/api";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { SignUpProps } from "../../interface";

const Signup = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [submitError, setSubmitError] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<SignUpProps>();
  const onSubmit = async (data: SignUpProps) => {
    if (data.password !== data.confirmPassword) {
      setPasswordsMatch(false);
      return;
    }
    try {
      const signupObject = {
        username: data.username,
        email: data.email,
        password: data.password,
      };
      const res = await signUser(signupObject);
      localStorage.setItem("jwt", res.jwt);
      localStorage.setItem("user", res.user);
      navigate("/streams");
    } catch (error) {
      if (error instanceof AxiosError) {
        setSubmitError(error?.response?.data?.error.message);
      }
    }
  };

  return (
    <Flex
      flexDirection={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100vh"}
      background={theme?.colors.background}
    >
      <Flex
        borderRadius={"20px"}
        flexDirection={"column"}
        style={{
          boxShadow: theme?.colors.boxShadowPrimary,
          border: `1px solid ${theme?.colors.borderPrimary}`,
        }}
      >
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          padding={"30px 140px 0px 140px "}
          style={{ gap: "1rem" }}
        >
          <Text fontSize="30px" fontWeight={"bold"}>
            Sign up
          </Text>
          <Text fontSize="16px" fontWeight={"500"}>
            Already have an account?
            <a
              style={{
                textDecoration: "none",
                color: theme?.colors?.primary || "yellow",
                fontWeight: "600",
              }}
              href="../Signin"
            >
              Signin
            </a>
          </Text>
        </Flex>

        <form
          style={{
            flexDirection: "column",
            marginBottom: "40px",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          {!!submitError && (
            <Box style={{ textAlign: "center", marginTop: "10px" }}>
              <Text color={theme?.colors.danger}>{submitError}</Text>
            </Box>
          )}
          <Label
            margin={"20px 60px"}
            fontSize="16px"
            fontWeight="600"
            flexDirection="column"
            style={{ color: theme?.colors.text }}
          >
            User name
            <TextInput
              type="text"
              placeholder="e.g Jhon Doe"
              fontSize="16px"
              style={{ color: theme?.colors.textDark }}
              fontWeight="550"
              {...register("username", {
                required: "Username is required",
                minLength: {
                  value: 3,
                  message: "Username should be at least 3 characters",
                },
              })}
            />
            {errors.username && (
              <Text color={theme?.colors.danger}>
                {errors.username.message}
              </Text>
            )}
          </Label>

          <Label
            margin={"20px 60px"}
            fontSize="16px"
            fontWeight="600"
            flexDirection="column"
            style={{ color: theme?.colors.text }}
          >
            Email address
            <TextInput
              type="email"
              placeholder="e.g Jhon Doe"
              fontSize="16px"
              fontWeight="550"
              style={{ color: theme?.colors.textDark }}
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "Invalid email address",
                },
              })}
            />
            {errors.email && (
              <Text color={theme?.colors.danger}>{errors.email.message}</Text>
            )}
          </Label>
          <Label
            margin={"20px 60px"}
            fontSize="16px"
            fontWeight="600"
            flexDirection="column"
            style={{ color: theme?.colors.text }}
          >
            Password
            <TextInput
              type="password"
              placeholder="Password"
              fontSize="16px"
              fontWeight="550"
              style={{ color: theme?.colors.textDark }}
              {...register("password", {
                required: "Password is required",
              })}
            />
            {errors.password && (
              <Text color={theme?.colors.danger}>
                {errors.password.message}
              </Text>
            )}
          </Label>
          <Label
            margin={"20px 60px"}
            fontSize="16px"
            fontWeight="600"
            flexDirection="column"
            style={{ color: theme?.colors.text }}
          >
            Confirm Password
            <TextInput
              type="password"
              placeholder="Confirm Password"
              fontSize="16px"
              fontWeight="550"
              style={{ color: theme?.colors.textDark }}
              {...register("confirmPassword")}
            />
            {!passwordsMatch && (
              <Text color={theme?.colors.danger}>Password should match</Text>
            )}
          </Label>
          <Flex justifyContent={"center"}>
            <Button
              style={{
                width: "40%",
                marginTop: "30px",
              }}
              type="submit"
              size={60}
            >
              Create account
            </Button>
          </Flex>
        </form>
      </Flex>
    </Flex>
  );
};
export default Signup;
