import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import { ThemeContext as StyledThemeContext } from "styled-components";

const useTheme = () => {
  const { toggleTheme, isDark } = useContext(ThemeContext);
  const theme = useContext(StyledThemeContext);
  return { isDark, toggleTheme, theme };
};
export default useTheme;
