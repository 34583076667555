import { Box, Flex } from "../../components/Box";
import Text from "../../components/Text/Text";
import useTheme from "../../hooks/useTheme";
import Button from "../../components/Button/Button";
import { BackIcon } from "../../config/images";
import Label from "../../components/Label/Label";
import TextInput from "../../components/TextInput/TextInput";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { ChainsProp, CreateStreamProps } from "../../interface";
import { createStream, getChains } from "../../api/api";
import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { ChainList } from "../../components/ListItem/types";
import { MultiSelectDropdown } from "../../components/Select/Dropdown";
import {
  EmailValidation,
  NotificationTypeEnum,
  ValidationErrors,
  webhookUrlValidation,
} from "../../utils/enum";
import Select from "../../components/Select/Select";
import { NotificationList } from "../../utils/constant";

const AddStream = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [submitError, setSubmitError] = useState("");
  const [chainsList, setChainsList] = useState<ChainList[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<ChainsProp[]>([]);
  const [chainError, setChainError] = useState(false);
  const [notificationType, setNotificationType] = useState(
    NotificationTypeEnum.Email
  );
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const getALLChains = async () => {
      const chainLists = await getChains();
      setChainsList(chainLists?.data?.data);
    };
    getALLChains();
  }, []);
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    setValue,
  } = useForm<CreateStreamProps>();
  //setting the chain values to handle the error handling
  useEffect(() => {
    const chainsID =
      selectedOptions && selectedOptions.map((item) => item.value);
    if (chainsID.length === 0) {
      setValue("chains", null);
      setChainError(true);
    } else {
      setValue("chains", chainsID);
      setChainError(false);
    }
  }, [selectedOptions, setValue]);

  const onSubmit = async (data: CreateStreamProps) => {
    const chainIds = selectedOptions && selectedOptions.map((obj) => obj.value);
    if (chainIds) {
      try {
        const addStream = {
          title: data.title,
          chains: chainIds,
          webhook_url: data.webhook_url,
        };
        await createStream({ data: addStream });
      } catch (error) {
        if (error instanceof AxiosError) {
          setSubmitError(error.response?.data.error.message);
          return;
        }
      }
    }
    navigate("/streams");
  };

  const handleOptionClick = (option: string) => {
    setNotificationType(option);
    setIsOpen(false);
  };
  return (
    <Flex
      background={theme?.colors.background}
      flexDirection={"column"}
      height={"auto"}
    >
      <Flex margin={"40px 110px"} flexDirection={"column"}>
        <Flex flexDirection={"row"} justifyContent={"space-between"}>
          <Button
            startIcon={<BackIcon size={20} style={{ marginRight: "8px" }} />}
            style={{
              marginTop: "20px",
            }}
            onClick={() => navigate("/streams")}
          >
            Go Back
          </Button>
        </Flex>
        <Text marginTop={"20px"} fontSize="30px" fontWeight="800">
          Create new stream
        </Text>
        <Text marginTop={"44px"} fontSize="30px" fontWeight="800">
          XRP Stream Configuration
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className="FormCss">
            {!!submitError && (
              <Box>
                <Text color={theme?.colors.danger}>{submitError}</Text>
              </Box>
            )}
            <Flex flexDirection={"column"}>
              <Flex alignItems={"center"}>
                <Label
                  fontSize="16px"
                  fontWeight="600"
                  flexDirection="column"
                  style={{ color: theme?.colors.text, width: "45%" }}
                >
                  Stream Configuration
                </Label>
              </Flex>

              <Flex
                flexDirection={"row"}
                flexWrap={"wrap"}
                style={{ gap: "20px" }}
              >
                <Box className="custom_box">
                  <TextInput
                    {...register("title", { required: true })}
                    type="text"
                    placeholder="Enter Title"
                    marginTop={"10px"}
                    className="custom_input"
                  />
                  {errors.title && (
                    <Text color={theme?.colors.danger} marginTop={"10px"}>
                      {ValidationErrors.Title}
                    </Text>
                  )}
                </Box>
                <Box className="custom_box" marginTop={"5px"}>
                  <Controller
                    name={"chains"}
                    control={control}
                    render={({ field }) => (
                      <MultiSelectDropdown
                        {...field}
                        {...register("chains", { required: true })}
                        options={chainsList}
                        placeholder="Select Chains"
                        fieldName="chains"
                        setValue={field.onChange}
                        setSelectedOptions={setSelectedOptions}
                        selectedOptions={selectedOptions}
                      />
                    )}
                  />

                  {errors.chains && chainError && (
                    <Text color={theme?.colors.danger} marginTop={"10px"}>
                      {ValidationErrors.Chains}
                    </Text>
                  )}
                </Box>
              </Flex>
              <Label
                fontSize="16px"
                fontWeight="600"
                flexDirection="column"
                style={{ color: theme?.colors.text, width: "45%" }}
              >
                Notification Type
              </Label>
              <Flex
                flexDirection={"row"}
                flexWrap={"wrap"}
                style={{ gap: "20px" }}
              >
                <Box className="custom_box" marginTop={"5px"}>
                  <Select
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    label={notificationType}
                    handleOptionClick={handleOptionClick}
                    options={NotificationList}
                  />
                </Box>

                <Box className="custom_box">
                  <TextInput
                    {...register("webhook_url", {
                      ...(notificationType ===
                        NotificationTypeEnum.WebHook_URL &&
                        webhookUrlValidation),
                      ...(notificationType === NotificationTypeEnum.Email &&
                        EmailValidation),
                    })}
                    type={"text"}
                    placeholder={
                      notificationType === NotificationTypeEnum.Email
                        ? "Enter Email"
                        : "Enter Webhook URL"
                    }
                    marginTop={"10px"}
                    className="custom_input"
                  />

                  {errors?.webhook_url?.type === "required" ? (
                    <Text color={theme?.colors.danger} marginTop={"10px"}>
                      {notificationType === NotificationTypeEnum.Email
                        ? "Email"
                        : " Stream webhook url"}{" "}
                      {""}
                      is required
                    </Text>
                  ) : (
                    <Text color={theme?.colors.danger} marginTop={"10px"}>
                      {errors?.webhook_url?.message}
                    </Text>
                  )}
                </Box>
              </Flex>

              <Button
                style={{
                  width: "25%",
                  margin: "20px auto",
                }}
              >
                Create Stream
              </Button>
            </Flex>
          </Box>
        </form>
      </Flex>
    </Flex>
  );
};
export default AddStream;
