import { useNavigate } from "react-router-dom";
import { CopyIcon, bnbChain } from "../../config/images";
import useTheme from "../../hooks/useTheme";
import { Box, Flex } from "../Box";
import Text from "../Text/Text";
import { StreamItemProps } from "./types";
import { Button, DropdownMenu, Badge } from "@radix-ui/themes";
import { deletStream, getStreams } from "../../api/api";
import { useRef, useState } from "react";
import { CheckIcon } from "@radix-ui/react-icons";
const StreamItem = (streamDetail: StreamItemProps) => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const streamIdRef = useRef<HTMLDivElement>(null);
  const [copyState, setCopyState] = useState(false);

  const handleDeleteStreams = async (id: number) => {
    await deletStream(id);
    const streamListing = await getStreams({
      page: 1,
      pageSize: 25,
    });
    streamDetail.setStreamList(streamListing.data.data);
  };
  const handleCopyToClipboard = () => {
    if (streamIdRef.current) {
      const streamIdText = streamIdRef.current.innerText;
      navigator.clipboard.writeText(streamIdText);
      setCopyState(true);
      setTimeout(() => {
        setCopyState(false);
      }, 2000);
    }
  };

  return (
    <Box
      borderRadius={"10px"}
      padding={"20px"}
      border={`1px solid ${theme?.colors.borderPrimary}`}
      marginTop={"10px"}
      width={"850px"}
      style={{
        boxShadow: theme?.colors.boxShadowPrimary,
      }}
    >
      <Flex flexDirection={"column"}>
        <Flex
          margin={"20px"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          <Flex
            margin={"5px"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"100%"}
            flexDirection={"row"}
          >
            <Flex alignItems={"center"}>
              <img
                src={bnbChain}
                alt="bitcoin"
                height={"20px"}
                width={"20px"}
                style={{ marginRight: "10px" }}
              />
              <Text fontSize="2rem" fontWeight={"bold"}>
                {streamDetail?.item?.title}
              </Text>
            </Flex>
            <Flex>
              <Badge color="green" variant="solid">
                Active
              </Badge>
              <DropdownMenu.Root>
                <DropdownMenu.Trigger>
                  <Button variant="soft" size={"2"}>
                    <svg
                      aria-hidden="true"
                      data-testid="test-icon"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      role="img"
                      color="#0B72C4"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12 14a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-7a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 14a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                      ></path>
                    </svg>
                  </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content>
                  <DropdownMenu.Item
                    onSelect={() => {
                      navigate("/updatestream", {
                        state: {
                          streamId: streamDetail.streamId,
                        },
                      });
                    }}
                  >
                    Edit
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    onSelect={() => handleDeleteStreams(streamDetail.streamId)}
                  >
                    Delete
                  </DropdownMenu.Item>
                  <DropdownMenu.Item>Pause Stream</DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            </Flex>
          </Flex>

          <Flex margin={"5px"} alignItems={"center"}>
            <Box
              style={{
                flexBasis: "10%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={{
                  color: theme?.colors.backgroundDisabled,
                }}
                fontSize="14px"
                fontWeight={"bold"}
              >
                Stream Id
              </Text>
              <Text fontSize="14px" fontWeight={"bold"}>
                :
              </Text>
            </Box>
            <Box style={{ flexBasis: "80%" }}>
              <Text
                ref={streamIdRef}
                style={{ color: theme?.colors.primary }}
                marginLeft={"10px"}
                fontSize="14px"
                fontWeight={"normal"}
              >
                {streamDetail?.item?.streamId}
                {copyState ? (
                  <CheckIcon
                    width="20"
                    height="20"
                    color={theme?.colors.text}
                  />
                ) : (
                  <CopyIcon
                    style={{ marginLeft: "6px", cursor: "pointer" }}
                    color={theme?.colors.primary}
                    size={18}
                    onClick={handleCopyToClipboard}
                  />
                )}
              </Text>
            </Box>
          </Flex>

          <Flex margin={"5px"} alignItems={"center"} flexWrap={"wrap"}>
            <Box
              style={{
                flexBasis: "10%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={{
                  color: theme?.colors.backgroundDisabled,
                }}
                fontSize="14px"
                fontWeight={"bold"}
              >
                Web Url
              </Text>
              <Text fontSize="14px" fontWeight={"bold"}>
                :
              </Text>
            </Box>
            <Box style={{ flexBasis: "80%" }}>
              <Text
                marginLeft={"10px"}
                fontSize="14px"
                fontWeight={"normal"}
                style={{ wordBreak: "break-all" }}
              >
                {streamDetail?.item?.webhook_url}
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
export default StreamItem;
