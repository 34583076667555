import { useNavigate } from "react-router-dom";
import {
  StartImage,
  awsImage,
  backgroundToolsImage,
  cloudImage,
  firebaseImage,
  parseImage,
  superBaseImage,
} from "../../config/images";
import useTheme from "../../hooks/useTheme";
import { Flex } from "../Box";
import Button from "../Button/Button";
import { CardTool } from "../Card";
import { Text } from "../Text";
import LayoutWapper from "../Layout/LayoutWapper";

const Tool = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  return (
    <LayoutWapper bgColor={theme?.colors.backgroundDisabled}>
      <Flex
        flexDirection={"row"}
        padding={"140px 140px"}
        background={theme?.colors.backgroundDisabled}
        margin={"auto"}
      >
        <Flex width={"50%"} flexDirection={"column"}>
          <Text
            style={{
              color: `${theme?.colors.textDark}`,
              width: "70%",
              lineHeight: 1,
            }}
            fontSize="60px"
            fontWeight={"bold"}
          >
            Work seamlessly with your favourite tools
          </Text>
          <Text
            style={{
              color: `${theme?.colors.textDark}`,
              width: "60%",
              marginTop: "20px",
            }}
            fontSize="22px"
            fontWeight={"400"}
          >
            Our APIs, SDKs, and data power Web3’s highest performing dapps, and
            integrate seamlessly into existing solution stacks.
          </Text>

          <Button
            onClick={() => navigate("/signup")}
            style={{
              width: "30%",
              marginTop: "30px",
              fontSize: "20px",
              backgroundColor: "transparent",
              border: `2.5px solid black`,
              color: "black",
            }}
            size={"60px"}
            type="submit"
          >
            <StartImage size={28} style={{ marginRight: "5px" }} />
            Start Now
          </Button>
        </Flex>
        <Flex
          width={"50%"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"space-evenly"}
          backgroundImage={`url(${backgroundToolsImage})`}
          backgroundRepeat={"no-repeat"}
          backgroundSize={"cover"}
        >
          <Flex justifyContent={"center"}>
            <CardTool image={firebaseImage} title="Firebase" link="" />
            <CardTool image={cloudImage} title="Google Cloud" link="" />
          </Flex>
          <Flex justifyContent={"center"}>
            <CardTool image={superBaseImage} title="Supabase" link="" />
            <CardTool image={parseImage} title="Parse" link="" />
            <CardTool image={awsImage} title="Amazon AWS" link="" />
          </Flex>
        </Flex>
      </Flex>
    </LayoutWapper>
  );
};
export default Tool;
