import styled from "styled-components";
import { nexaLogo } from "../../config/images";
import { Box, Flex } from "../Box";

export default function Header() {
  return (
    <>
      <StyledHeader>
        <Flex>
          <Box>
            <img
              style={{
                width: 300,
              }}
              src={nexaLogo}
              alt="logo"
            />
          </Box>
        </Flex>
      </StyledHeader>
    </>
  );
}
const StyledHeader = styled.header`
  width: 100%;
  border-radius: 0px 0px 15px 15px;
  padding-left: 125px;
`;
