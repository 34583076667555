import { MediaQueries } from "./types";

export const breakPoints = {
  sm: 576,
  md: 852,
  lg: 968,
  xl: 1080,
};

const mediaQueries: MediaQueries = {
  sm: `@media screen and (max-width: ${breakPoints.sm}px)`,
  md: `@media screen and (max-width: ${breakPoints.md}px)`,
  lg: `@media screen and (max-width: ${breakPoints.lg}px)`,
  xl: `@media screen and (max-width: ${breakPoints.xl}px)`,
};

const radii = {
  small: "4px",
  default: "16px",
  card: "32px",
  circle: "50%",
};

export default {
  siteWidth: 1200,
  mediaQueries,
  radii,
};
