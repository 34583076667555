import axios from "axios";
import { Configurations } from "../config/configuration";
import {
  AddAddressApiResponse,
  CreateStreamApiResponse,
  ForgotPasswordProps,
  ResetPasswordProps,
  SignUpProps,
} from "../interface";
import qs from "qs";

/**AXIOS CLIENT */
const httpClient = axios.create({
  baseURL: Configurations.BASE_URL,
});

export const signUser = async (data: SignUpProps) => {
  try {
    const response = await httpClient({
      method: "POST",
      url: "/auth/local/register",
      data: data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/** User Login*/
export const loginUser = async (data: any) => {
  try {
    const response = await httpClient({
      method: "POST",
      url: "/auth/local",
      data: data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**Create Stream */
export const createStream = async (data: CreateStreamApiResponse) => {
  const token = localStorage.getItem("jwt");

  try {
    const response = await httpClient({
      method: "POST",
      url: "/streams",
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
/** Update Stream */
export const updateStream = async (
  data: CreateStreamApiResponse,
  id: number
) => {
  const token = localStorage.getItem("jwt");

  try {
    const response = await httpClient({
      method: "Put",
      url: `streams/${id}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

/** Get Stream */
export const getStreams = async ({ page, pageSize }: any) => {
  const token = localStorage.getItem("jwt");
  const query = {
    populate: "*",
    pagination: {
      page,
      pageSize,
    },
  };
  const queryString = qs.stringify(query);
  try {
    const response = await httpClient({
      method: "GET",
      url: `/streams?${queryString}`,
      headers: {
        Authorization: `Bearer ${token}`,
        // "ngrok-skip-browser-warning":"69420"
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getStreamById = async (id: number) => {
  const token = localStorage.getItem("jwt");
  try {
    const response = await httpClient({
      method: "GET",
      url: `/streams/${id}?populate=*`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getChains = async () => {
  const token = localStorage.getItem("jwt");
  try {
    const response = await httpClient({
      method: "GET",
      url: "/chains",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const addStreamAddress = async (data: AddAddressApiResponse) => {
  const token = localStorage.getItem("jwt");

  try {
    const response = await httpClient({
      method: "POST",
      url: "/addresses",
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const removeStreamAddress = async (id: number) => {
  const token = localStorage.getItem("jwt");

  try {
    const response = await httpClient({
      method: "DELETE",
      url: `/addresses/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const deletStream = async (id: number) => {
  const token = localStorage.getItem("jwt");

  try {
    const response = await httpClient({
      method: "DELETE",
      url: `/streams/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const forgotPassword = async (data: ForgotPasswordProps) => {
  try {
    const response = await httpClient({
      method: "POST",
      url: "/auth/forgot-password",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const ResetPasswords = async (data: ResetPasswordProps) => {
  try {
    const response = await httpClient({
      method: "POST",
      url: "/auth/reset-password",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export default {};
