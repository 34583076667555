import Modal from "../../components/Modal/Modal";
import Flex from "../../components/Box/Flex";
import Label from "../../components/Label/Label";
import TextInput from "../../components/TextInput/TextInput";
import useTheme from "../../hooks/useTheme";
import { AddAddressProps } from "../../components/ListItem/types";
import { useForm } from "react-hook-form";
import { AddAddressFormProps } from "../../interface";
import Text from "../../components/Text/Text";
import { ValidationErrors } from "../../utils/enum";
import { useState } from "react";
import { Box } from "../../components/Box";
import { AxiosError } from "axios";
import { addStreamAddress, getStreamById } from "../../api/api";
import DialogAlert from "../../components/AlertDialog/AlertDialog";

const AddAddress = (props: AddAddressProps) => {
  const { theme } = useTheme();
  const { openModal, setStreams, setOpenModal, streamId } = props;
  const [submitError, setSubmitError] = useState("");
  const [dialogAlert, setDialogAlert] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm<AddAddressFormProps>();
  const onSubmit = async (data: AddAddressFormProps) => {
    try {
      const addStreamAddressObj = {
        ...data,
        stream: streamId,
      };

      await addStreamAddress({ data: addStreamAddressObj });
      setOpenModal(false);
      reset();
      const streamObject = await getStreamById(streamId);
      setStreams(streamObject.data.data);
      setDialogAlert(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        setSubmitError(error.response?.data.error.message);
        return;
      }
    }
  };
  return (
    <>
      <Modal
        active={openModal}
        title={"Add  Wallet Address"}
        acceptText="Add"
        hideModal={() => setOpenModal(false)}
        onAccept={handleSubmit(onSubmit)}
        children={
          <>
            <form>
              {!!submitError && (
                <Box>
                  <Text color={theme?.colors.danger}>{submitError}</Text>
                </Box>
              )}
              <Flex
                alignItems={"center"}
                flexDirection={"row"}
                style={{ gap: "20px" }}
              >
                <Label
                  style={{ color: theme?.colors.text }}
                  fontSize="16px"
                  fontWeight="600"
                  flexDirection="column"
                >
                  Address:
                </Label>
                <Flex flexDirection={"column"} width={"80%"}>
                  <TextInput
                    {...register("address", { required: true })}
                    type="text"
                    placeholder="Enter wallet address"
                    fontSize="16px"
                    fontWeight="550"
                    marginTop={"10px"}
                    width="100%"
                    className="custom_input"
                  />
                  {errors.address && (
                    <Text color={theme?.colors.danger} marginTop={"5px"}>
                      {ValidationErrors.Address}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </form>
          </>
        }
      />
      <DialogAlert
        active={dialogAlert}
        title={"Success!"}
        description={"Stream address added successfully"}
        onClose={(event) => {
          setDialogAlert(false);
        }}
      />
    </>
  );
};

export default AddAddress;
