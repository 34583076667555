import { Colors } from "./types";

export const baseColors = {
  failure: "#ED4B9E",
  primary: "#4287f5",
  primaryBright: "#4287f5",
  primaryDark: "#4287f5A1",
  secondary: "#7645D9",
  success: "#31D0AA",
  warning: "#FFB237",
  danger: "#D9534f",
};

export const brandColors = {
  binance: "#F0B90B",
};

export const lightColors: Colors = {
  ...baseColors,
  ...brandColors,
  background: "#0C0A17",
  backgroundDisabled: "#F1F2F3",
  backgroundAlt: "#FFFFFF",
  backgroundLight: "#171717",
  borderPrimary: "#3c3c44",
  boxShadowPrimary: "-4px 3px 31px -6px rgba(111,103,110,0.91)",
  textDark: "#041836",
  textLight: "#FFFFFF",
  text: "#E9EAEB",
  textDisabled: "#BDC2C4",
  borderColor: "#E9EAEB",
  gradientShadePrimary: "#BDC2C4",
  gradientShadeSecondary: "#FFFFFF",
  textTitleSecondary: "#5B8DB9",
};

export const darkColors: Colors = {
  ...baseColors,
  ...brandColors,
  background: "#0C0A17",
  backgroundDisabled: "#F1F2F3",
  backgroundAlt: "#FFFFFF",
  backgroundLight: "#171717",
  borderPrimary: "#3c3c44",
  textDark: "#041836",
  text: "#E9EAEB",
  textLight: "#FFFFFF",
  textDisabled: "#BDC2C4",
  borderColor: "#E9EAEB",
  gradientShadePrimary: "#BDC2C4",
  gradientShadeSecondary: "#FFFFFF",
  textTitleSecondary: "#5B8DB9",
  boxShadowPrimary: "-4px 3px 31px -6px rgba(111,103,110,0.91)",
};
