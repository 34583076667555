export const Network = {
  Staging: "Staging",
  Production: "Production",
};

const Url = {
  BaseUrlStaging: "https://dev.api.nexastream.io/api",
  BaseUrlProduction: "https://api-sahal.mrhb.network",
};
const getUrl = (enviroment: string) => {
  return enviroment == Network.Staging
    ? Url.BaseUrlStaging
    : Url.BaseUrlProduction;
};

export const Configurations = {
  BASE_URL: getUrl(Network.Staging),
};

export default {
  Configurations,
};
