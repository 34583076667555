import ReactTyped from "react-typed";
import { Flex } from "../Box";
import { Text } from "../Text";
import Button from "../Button/Button";
import {
  ForwardIcon,
  blockchainImage,
  deltaImage,
  metaMaskImage,
  polygonImage,
  opbnblogo,
} from "../../config/images";
import useTheme from "../../hooks/useTheme";
import { useNavigate } from "react-router-dom";
import LayoutWapper from "../Layout/LayoutWapper";
import styled from "styled-components";

const Hero = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();

  return (
    <LayoutWapper bgColor={theme?.colors.background}>
      <Flex
        paddingLeft={"140px"}
        paddingRight={"140px"}
        flexDirection={"column"}
        margin={"auto"}
      >
        <Flex flexDirection={"row"}>
          <Flex flexDirection={"column"}>
            <Flex marginTop={"80px"} flexDirection={"column"}>
              <Text
                style={{
                  width: "30%",
                  background: `-webkit-linear-gradient(360deg, ${theme?.colors.gradientShadePrimary}, ${theme?.colors.gradientShadeSecondary})`,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  lineHeight: 1,
                }}
                fontSize="80px"
                fontWeight={"bold"}
              >
                Build
              </Text>
              <ReactTyped
                strings={["blockchain streams "]}
                style={{
                  width: "100%",
                  background: `-webkit-linear-gradient(360deg, ${theme?.colors.gradientShadePrimary}, ${theme?.colors.gradientShadeSecondary})`,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontSize: "70px",
                  fontWeight: "bold",
                  lineHeight: 1.5,
                }}
                smartBackspace={true}
                typeSpeed={150}
                loop
              />
              <Text
                style={{
                  width: "80%",
                  background: `-webkit-linear-gradient(360deg, ${theme?.colors.gradientShadePrimary}, ${theme?.colors.gradientShadeSecondary})`,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  lineHeight: 1,
                }}
                fontSize="80px"
                fontWeight={"bold"}
              >
                faster than ever
              </Text>
              <Text
                marginTop={"20px"}
                marginBottom={"20px"}
                style={{
                  width: "60%",
                  background: `-webkit-linear-gradient(360deg, ${theme?.colors.gradientShadePrimary}, ${theme?.colors.gradientShadeSecondary})`,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                fontSize="24px"
                fontWeight={"600"}
              >
                Enterprise-grade APIs and real-time blockchain data that make
                Web3 development as easy as Web2.
              </Text>
            </Flex>
            <Flex>
              <Button
                onClick={() => navigate("/signup")}
                style={{
                  width: "30%",
                  marginTop: "30px",
                  fontSize: "18px",
                  marginRight: "40px",
                }}
                size={"70px"}
                type="submit"
              >
                <ForwardIcon style={{ marginRight: "5px" }} />
                Get Started
              </Button>
              <Button
                onClick={() => navigate("/signin")}
                style={{
                  width: "30%",
                  marginTop: "30px",
                  fontSize: "22px",
                  backgroundColor: "transparent",
                  border: `2px solid white`,
                }}
                type="submit"
                size={"70px"}
              >
                Sign in
              </Button>
            </Flex>
          </Flex>
          <StyledFlexImage>
            <img
              style={{
                filter: "drop-shadow(3px 48px 100px 	#ff9a00	)",
                width: "60%",
                height: "60vh",
                objectFit: "contain",
              }}
              alt=""
              src={opbnblogo}
            />
          </StyledFlexImage>
        </Flex>

        <Flex
          marginTop={"180px"}
          marginBottom={"60px"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Text fontWeight={600} fontSize="24px">
            TRUSTED BY INDUSTRY LEADERS
          </Text>
          <Flex marginTop={"14px"}>
            <img
              style={{ margin: "10px" }}
              src={blockchainImage}
              width={"220px"}
              height={"60px"}
              alt=""
            />
            <img
              style={{ margin: "10px" }}
              src={deltaImage}
              width={"220px"}
              height={"60px"}
              alt=""
            />
            <img
              style={{ margin: "10px" }}
              src={metaMaskImage}
              width={"220px"}
              height={"60px"}
              alt=""
            />
            <img
              style={{ margin: "10px" }}
              src={polygonImage}
              width={"220px"}
              height={"60px"}
              alt=""
            />
          </Flex>
        </Flex>
      </Flex>
    </LayoutWapper>
  );
};

const StyledFlexImage = styled(Flex)`
  width: 60%;
  display: flex;
  margin-top: 70px;
`;
export default Hero;
