import { useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import { DropdownIcon, MultiSelectDropdownWrapper } from "./Dropdown";
import { SelectProps } from "../../interface";

const Select = (props: SelectProps) => {
  const { isOpen, setIsOpen, label, handleOptionClick, options } = props;
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  return (
    <MultiSelectDropdownWrapper
      className="custom_input"
      onClick={toggleDropdown}
      ref={dropdownRef}
    >
      <div>{label}</div>
      <DropdownIcon />

      <OptionsWrapper isOpen={isOpen}>
        {options.map(
          (option: {
            value: string;
            disabled: boolean | undefined;
            label: string | number | boolean | null;
          }) => (
            <Option
              key={option.value}
              disabled={option.disabled}
              onClick={() => handleOptionClick(option.value)}
            >
              {option.label}
            </Option>
          )
        )}
      </OptionsWrapper>
    </MultiSelectDropdownWrapper>
  );
};

export default Select;
interface OptionsWrapperProps {
  isOpen: boolean;
}
interface OptionProps {
  disabled?: boolean;
}

export const OptionsWrapper = styled.div<OptionsWrapperProps>`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 100px;
  overflow-y: auto;
  z-index: 1;
  background: ${({ theme }) => theme.colors.backgroundLight};
`;

export const SelectedOption = styled.div`
  padding: 10px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.backgroundLight};
  color: ${({ theme }) => theme.colors.textLight};
  cursor: pointer;
`;

export const Option = styled.option<OptionProps>`
  padding: 10px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  background: ${({ theme }) => theme.colors.backgroundLight};

  &:hover {
    ${({ disabled }) =>
      !disabled &&
      css`
        background: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.textLight};
      `};
  }
`;
