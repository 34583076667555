import { ThemeContextProvider } from "./context/ThemeContext";
import { Theme as RadixUIProvider } from '@radix-ui/themes';
const Providers = ({ children }: any) => {
  return <ThemeContextProvider>
    <RadixUIProvider>
      {children}
    </RadixUIProvider>
  </ThemeContextProvider>;
};
export default Providers;
