import { styled } from "styled-components";
import { FlexProps } from "./types";
import {
  background,
  border,
  flexbox,
  layout,
  position,
  space,
} from "styled-system";
import Box from "./Box";

const Flex = styled(Box)<FlexProps>`
  display: flex;
  ${flexbox}
`;
export default Flex;
