import React from "react";
import { Flex } from "../Box";

const LayoutWapper = ({
  children,
  bgColor,
}: {
  children: React.ReactElement;
  bgColor: string;
}) => {
  return <Flex background={bgColor}>{children}</Flex>;
};
export default LayoutWapper;
