import { useState } from "react";
import { Flex, Box } from "../../components/Box";
import Button from "../../components/Button/Button";
import Label from "../../components/Label/Label";
import Text from "../../components/Text/Text";
import TextInput from "../../components/TextInput/TextInput";
import useTheme from "../../hooks/useTheme";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../api/api";
import { AxiosError } from "axios";
import { routes } from "../../utils/constant";

const Signin = () => {
  const navigate = useNavigate();
  const [identifier, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [submitError, setSubmitError] = useState("");
  const { theme } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await loginUser({ identifier, password });
      localStorage.setItem("jwt", res.jwt);
      localStorage.setItem("user", res.user);
    } catch (error) {
      if (error instanceof AxiosError) {
        setSubmitError(error?.response?.data?.error.message);
      }
    } finally {
      setIsLoading(false);
      navigate(routes.Stream);
    }
  };
  const onChangeUserName = (identifier: string) => {
    setUserName(identifier.toString());
  };
  const onChangePassword = (password: string) => {
    setPassword(password);
  };

  return (
    <Flex
      flexDirection={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100vh"}
      background={theme?.colors.background}
    >
      <Flex
        borderRadius={"20px"}
        flexDirection={"column"}
        style={{
          boxShadow: theme?.colors.boxShadowPrimary,
          border: `1px solid ${theme?.colors.borderPrimary}`,
        }}
      >
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          padding={"30px 140px 0px 140px "}
          style={{ gap: "1rem" }}
        >
          <Text fontSize="30px" fontWeight={"bold"}>
            Sign in
          </Text>
          <Text fontSize="16px" fontWeight={"500"}>
            Don't have an account?{" "}
            <a
              style={{
                textDecoration: "none",
                color: theme?.colors?.primary || "yellow",
                fontWeight: "600",
              }}
              href="../Signup"
            >
              Signup
            </a>
          </Text>
        </Flex>

        <form
          style={{
            flexDirection: "column",
            marginBottom: "40px",
          }}
          onSubmit={handleSubmit}
        >
          {!!submitError && (
            <Box style={{ textAlign: "center" }}>
              <Text color={theme?.colors.danger}>{submitError}</Text>
            </Box>
          )}
          <Label
            margin={"20px 60px"}
            fontSize="16px"
            fontWeight="600"
            flexDirection="column"
            style={{ color: theme?.colors.text }}
          >
            User name
            <TextInput
              type="text"
              placeholder="e.g Jhon Doe"
              fontSize="16px"
              fontWeight="550"
              style={{ color: theme?.colors.textDark }}
              value={identifier}
              onChange={(event) => onChangeUserName(event.target.value)}
            />
          </Label>
          <Label
            margin={"20px 60px"}
            fontSize="16px"
            fontWeight="600"
            flexDirection="column"
            style={{ color: theme?.colors.text }}
          >
            Password
            <TextInput
              type="password"
              placeholder="Password"
              fontSize="16px"
              fontWeight="550"
              style={{ color: theme?.colors.textDark }}
              value={password}
              onChange={(event) => onChangePassword(event.target.value)}
            />
          </Label>
          <Text
            style={{
              color: theme?.colors.primary,
              cursor: "pointer",
              textAlign: "end",
            }}
            margin={" 20px 60px"}
            fontSize="14px"
            fontWeight={"normal"}
            onClick={() => navigate(routes.ForgotPassword)}
          >
            Forgot Password?
          </Text>
          <Flex justifyContent={"center"}>
            <Button
              style={{
                width: "40%",
                marginTop: "30px",
              }}
              type="submit"
              size={60}
              disabled={isLoading}
            >
              {isLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Sign in"
              )}
            </Button>
          </Flex>
        </form>
      </Flex>
    </Flex>
  );
};
export default Signin;
