import { useNavigate } from "react-router-dom";
import { ForwardIcon } from "../../config/images";
import useTheme from "../../hooks/useTheme";
import { Flex } from "../Box";
import Button from "../Button/Button";
import { Text } from "../Text";
import LayoutWapper from "../Layout/LayoutWapper";

const About = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  return (
    <LayoutWapper bgColor={theme?.colors.background}>
      <Flex
        flexDirection={"column"}
        padding={"200px 140px"}
        alignItems={"center"}
        margin={"auto"}
      >
        <Text fontSize="50px" fontWeight={"bold"}>
          Get Started with Blockchain SDK
        </Text>

        <Text
          style={{
            width: "55%",
            marginTop: "20px",
          }}
          textAlign={"center"}
          fontSize="22px"
          fontWeight={"400"}
        >
          Bring Web3 to any platform. Install our light SDK and let Moralis do
          all the blockchain-specific groundwork so you can focus on your use
          case.
        </Text>

        <Flex width={"35%"}>
          <Button
            onClick={() => navigate("/signup")}
            style={{
              width: "100%",
              marginTop: "30px",
              fontSize: "18px",
              marginRight: "40px",
            }}
            size={"70px"}
            type="submit"
          >
            <ForwardIcon style={{ marginRight: "5px" }} />
            Get Started
          </Button>
          <Button
            onClick={() => navigate("/signin")}
            style={{
              width: "100%",
              marginTop: "30px",
              fontSize: "22px",
              backgroundColor: "transparent",
              border: `2px solid white`,
            }}
            type="submit"
            size={"70px"}
          >
            Sign in
          </Button>
        </Flex>
        <Flex justifyContent={"center"} marginTop={"120px"}>
          <Flex justifyContent={"center"} alignItems={"center"}>
            <Text
              style={{ color: theme?.colors.textTitleSecondary }}
              fontSize="50px"
              fontWeight={"bold"}
            >
              24/7
            </Text>
            <Text
              style={{
                width: "40%",
                color: theme?.colors.textTitleSecondary,
              }}
              fontSize="20px"
              fontWeight={"500"}
              marginLeft={"15px"}
            >
              Worldwide support
            </Text>
          </Flex>
          <Flex justifyContent={"center"} alignItems={"center"}>
            <Text
              style={{
                color: theme?.colors.textTitleSecondary,
              }}
              fontSize="50px"
              fontWeight={"bold"}
            >
              87%
            </Text>
            <Text
              style={{
                color: theme?.colors.textTitleSecondary,
                width: "50%",
              }}
              fontSize="20px"
              fontWeight={"500"}
              marginLeft={"15px"}
            >
              Average reduction in time-to-market
            </Text>
          </Flex>
          <Flex justifyContent={"center"} alignItems={"center"}>
            <Text
              style={{
                color: theme?.colors.textTitleSecondary,
              }}
              fontSize="50px"
              fontWeight={"bold"}
            >
              $86.4M
            </Text>
            <Text
              style={{ width: "40%", color: theme?.colors.textTitleSecondary }}
              fontSize="20px"
              marginLeft={"15px"}
              fontWeight={"500"}
            >
              Total engineering costs saved
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </LayoutWapper>
  );
};
export default About;
