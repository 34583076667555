import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import GlobalStyle from "./style/Global";
import Signup from "./pages/Signup/Signup";
import Signin from "./pages/Signin/Signin";
import Streams from "./pages/Streams/Streams";
import AddStream from "./pages/AddStream/AddSteam";
import UpdateStream from "./pages/UpdateStream/UpdateStream";
import Home from "./pages/Home/Home";
import ProtectedRoutes from "./router/ProtectedRoute";
import ResetPassword from "./pages/RestPassword/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
const router = createBrowserRouter([
  {
    path: "/signup",
    element: <Signup />,
  },

  {
    path: "/signin",
    element: <Signin />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },

  {
    element: <ProtectedRoutes />,
    children: [
      {
        path: "/streams",
        element: <Streams />,
      },
      {
        path: "/addstream",
        element: <AddStream />,
      },
      {
        path: "/updatestream",
        element: <UpdateStream />,
      },
    ],
  },

  {
    path: "/",
    element: <Home />,
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
      <GlobalStyle />
    </>
  );
}

export default App;
