import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Flex, Box } from "../Box";
import Button from "../../components/Button/Button";
import { routes } from "../../utils/constant";
import { nexaLogo } from "../../config/images";

interface ISidebarStyleProps {
  isOpen: boolean;
}

const SidebarMenu = () => {
  const navigate = useNavigate();

  const [isOpen] = useState(true);
  const handleLogout = () => {
    localStorage.removeItem("jwt");
    navigate(routes.Login);
  };
  return (
    <SidebarWrapper isOpen={isOpen}>
      {isOpen && (
        <Flex flexDirection="column">
          <SideBarLayout>
            <SideBarHeader>
              <SideBarLog>
                <Box
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/")}
                  padding={"0px 10px"}
                >
                  <img
                    style={{
                      width: 230,
                    }}
                    src={nexaLogo}
                    alt="logo"
                  />
                </Box>
              </SideBarLog>
            </SideBarHeader>
            <Box padding={"1rem"}>
              <Button width={"100%"} padding={"0 20px"} onClick={handleLogout}>
                Logout
              </Button>
            </Box>
          </SideBarLayout>
        </Flex>
      )}
    </SidebarWrapper>
  );
};

export default SidebarMenu;

const SidebarWrapper = styled.div<ISidebarStyleProps>`
  width: 250px;
  transition: width 0.5s ease;
  background-color: ${(props) => props.theme.colors.background};
  border-right: 3px solid;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
`;

const SideBarLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`;
const SideBarHeader = styled.div`
  height: 100px;
  min-height: 100px;
  display: flex;
  align-items: center;
`;
const SideBarLog = styled.div`
  display: flex;
  align-items: center;
`;
