import { useForm } from "react-hook-form";
import { Flex, Box } from "../../components/Box";
import Button from "../../components/Button/Button";
import Label from "../../components/Label/Label";
import Text from "../../components/Text/Text";
import TextInput from "../../components/TextInput/TextInput";
import useTheme from "../../hooks/useTheme";
import { ResetPasswordProps } from "../../interface";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { AxiosError } from "axios";
import { ResetPasswords } from "../../api/api";
import DialogAlert from "../../components/AlertDialog/AlertDialog";
import { routes } from "../../utils/constant";

const ResetPassword = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [submitError, setSubmitError] = useState("");
  const { search } = useLocation();
  const jwtToken = new URLSearchParams(search).get("code");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [dialogAlert, setDialogAlert] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm<ResetPasswordProps>();
  const onSubmit = async (data: ResetPasswordProps) => {
    if (data.password !== data.passwordConfirmation) {
      setPasswordsMatch(false);
      return;
    } else setPasswordsMatch(true);
    try {
      const ResetPasswordObj = {
        password: data.password,
        passwordConfirmation: data.passwordConfirmation,
        code: jwtToken,
      };
      await ResetPasswords(ResetPasswordObj);
      setSubmitError("");
      reset();
      setDialogAlert(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        setSubmitError(error?.response?.data?.error.message);
      }
    }
    console.log(data);
  };
  return (
    <>
      <Flex
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"100vh"}
        background={theme?.colors.background}
      >
        <Flex
          borderRadius={"20px"}
          flexDirection={"column"}
          style={{
            boxShadow: theme?.colors.boxShadowPrimary,
            border: `1px solid ${theme?.colors.borderPrimary}`,
          }}
        >
          <Flex
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            padding={"30px 140px 0px 140px "}
          >
            <Text fontSize="30px" fontWeight={"bold"}>
              Reset Password
            </Text>
          </Flex>

          <form
            style={{
              flexDirection: "column",
              marginBottom: "40px",
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            {!!submitError && (
              <Box style={{ textAlign: "center", marginTop: "10px" }}>
                <Text color={theme?.colors.danger}>{submitError}</Text>
              </Box>
            )}

            <Label
              margin={"20px 60px"}
              fontSize="16px"
              fontWeight="600"
              flexDirection="column"
              style={{ color: theme?.colors.text }}
            >
              Password
              <TextInput
                type="password"
                placeholder="Password"
                fontSize="16px"
                fontWeight="550"
                style={{ color: theme?.colors.textDark }}
                {...register("password", {
                  required: "Password is required",
                })}
              />
              {errors.password && (
                <Text color={theme?.colors.danger}>
                  {errors.password.message}
                </Text>
              )}
            </Label>
            <Label
              margin={"20px 60px"}
              fontSize="16px"
              fontWeight="600"
              flexDirection="column"
              style={{ color: theme?.colors.text }}
            >
              Confirm Password
              <TextInput
                type="password"
                placeholder="Confirm Password"
                fontSize="16px"
                fontWeight="550"
                style={{ color: theme?.colors.textDark }}
                {...register("passwordConfirmation")}
              />
              {!passwordsMatch && (
                <Text color={theme?.colors.danger}>Password should match</Text>
              )}
            </Label>

            <Flex marginTop={"1rem"} justifyContent={"center"}>
              <Button
                style={{
                  marginRight: "10px",
                }}
                type="button"
                onClick={() => navigate(routes.Login)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                style={{
                  backgroundColor: theme?.colors.success,
                }}
              >
                Reset
              </Button>
            </Flex>
          </form>
        </Flex>
      </Flex>
      <DialogAlert
        active={dialogAlert}
        title={"Success!"}
        description={"Password Reset Successfully"}
        onClose={(event) => {
          setDialogAlert(false);
          navigate(routes.Login);
        }}
      />
    </>
  );
};

export default ResetPassword;
