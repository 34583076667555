import { Flex } from "../../components/Box";
import Header from "../../components/Header";
import { About, Hero, Tool } from "../../components/Landing";

const Home = () => {
  return (
    <Flex flexDirection={"column"}>
      <Header />
      <Hero />
      <Tool />
      <About />
    </Flex>
  );
};
export default Home;
