export enum ValidationErrors {
  Title = "Stream title is required",
  Address = "Stream address is required",
  User_Name = "Username is required",
  Webhook_Url = "Stream webhook url is required",
  Chains = "Stream chains is required",
  Webhook_Url_Pattern = "Webhook url is not valid",
  Email = "Email is required",
}
export enum ErrorStatus {
  REQUIRED = "required",
}
export const webhookUrlValidation = {
  required: ValidationErrors.Webhook_Url,
  pattern: {
    value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
    message: "Enter a valid Stream Webhook URL",
  },
  maxLength: {
    value: 500,
    message: "Stream Webhook URL must be at most 500 characters",
  },
};

export const EmailValidation = {
  required: ValidationErrors.Email,
  pattern: {
    value: /^\S+@\S+$/i,
    message: "Enter a valid email address",
  },
};
export const NotificationTypeEnum = {
  Email: "Email",
  WebHook_URL: "Webhook Url",
};
