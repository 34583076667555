import Button from "../Button/Button";
import Flex from "../Box/Flex";
import { Fragment, useEffect } from "react";
import styled from "styled-components";
import useTheme from "../../hooks/useTheme";

type ModalProps = {
  title?: string;
  children?: React.ReactNode;
  active?: boolean;
  description?: string;
  acceptText?: string;
  hideModal?: () => void;
  onAccept?: () => void;
};
const Modal = (props: ModalProps) => {
  const { theme } = useTheme();
  const {
    title,
    children,
    active,
    description,
    acceptText,
    hideModal,
    onAccept,
  } = props;
  const doc: Document = document;
  const body = doc.querySelector("body");
  useEffect(() => {
    active
      ? body?.classList.add("overflow")
      : body?.classList.remove("overflow");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <Fragment>
      {active && (
        <ModalBlock>
          <ModalOverlay></ModalOverlay>
          <ModalContainer>
            <ModalHeader>
              <ModalTitle>{title}</ModalTitle>
              <ModalDescription>{description}</ModalDescription>
            </ModalHeader>
            <ModalBody>
              <>{children}</>
            </ModalBody>
            <ModalFooter>
              <Flex marginTop={"20px"} justifyContent={"center"}>
                <Button
                  style={{
                    marginRight: "10px",
                  }}
                  type="button"
                  onClick={hideModal}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  style={{
                    backgroundColor: theme?.colors.success,
                  }}
                  onClick={onAccept}
                >
                  {acceptText}
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContainer>
        </ModalBlock>
      )}
    </Fragment>
  );
};
export const ModalBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 400;
`;

export const ModalOverlay = styled.div`
  cursor: default;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const ModalContainer = styled.div`
  box-shadow: ${({ theme }) => theme.colors.boxShadowPrimary};
  border: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  backdrop-filter: blur(100px);
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  max-height: 75vh;
  max-width: 550px;
  padding: 2rem;
  width: 100%;
  animation: slide-down 0.2s ease 1;
  z-index: 1;
  overflow-y: auto;
  margin: auto;
  position: fixed;
  top: 50%; /* Adjust the vertical position */
  left: 50%; /* Adjust the horizontal position */
  transform: translate(-50%, -50%);
`;

export const ModalBody = styled.div`
  position: relative;
`;

export const ModalHeader = styled.div`
  justify-content: space-between;
  color: $gray9;
  margin: auto;
  padding-bottom: 1rem;
`;

export const ModalTitle = styled.h2`
  text-transform: capitalize;
  text-align: center;
  color: ${(theme) => theme.theme.colors.text};
`;

export const ModalDescription = styled.h4`
  text-align: center;
  color: ${(theme) => theme.theme.colors.text};
  margin: 0;
`;

export const ModalFooter = styled.div`
  padding: 10px 0px;
`;

export default Modal;
