import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
*{
    font-family: sans-serif;
}
body{
    background-color: ${({ theme }) => theme.colors.background} !important;
     &.overflow {
    overflow: hidden !important;
  }
  @keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border; }
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }
@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color:  ${({ theme }) => theme.colors.background};
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow; }
.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }
@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }
}`;
export default GlobalStyle;
